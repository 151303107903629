
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {apiUrl} from '../constant';
import {authHeader} from '../helpers/auth-header';
import {authtoken} from 'global.js';
const axios = require("axios");
export const clientmanageService = {
    addclient,
    addjobclient,
    create_amendment,
    getphasestl,
    getparticular_client,
    createclient,
    updatejobclient,
    getclientlist,
    getsubclientlist,
    getjobdocs,
    getClientTransition,
    gettransitionType ,
    getTransitionFields,
    getTransitionFieldData,
    addtransitionFieldData,
    getuniqueclient,
    editgovernancedata,
    getgovernance,
    editgovernancetransdata,
    getGovernanceCalltransdata ,
    addgovernancetransdata,
    gettransitionData ,
    generateForm,
    updateTransitionForm,
    updateTransitionSOPForm,
    updateTransData ,
    getcllientfrequency ,
    addclientfrequency,
    getclientdetails ,
    addinternalgovernancetransdata,
    getInternalGovernanceCalltransdata,
    getinternalgovernance,
    editinternalgovernancetransdata,
    getusersclientlist, 
    generateSOPForm,
    getuniqueclientforsme,
    getclienttransitionsmeData,
    getAppreciationList,
    getusers,
    addclientappreciation,
    getsingleAppreciation,
    updateclientappreciation,
    deleteClientAppreciation,
    getclienttransitionDash,
    addsubclient,
    download_transitionform_report,
    getInternalCalltransdata ,
    addinternaltransdata ,
    getinternalcall ,
    editinternaltransdata,
    updateQuestionaireForm,
    getquestionnaireData,
    getquestionnaireDash,
    governanceCalldashboard,
    getGovernanceMISdashboard,
    getGovernanceMISActiondashboard,
    editgovernancefreqdata,
    getclientfreq,
    governanceCalldashboarddetails,
    governanceCalldashboardactiondetails,
    getclient,
    getsubclient,
    mergeClient,
    saveManagerRejection,
    getfilterlist,
    editManagerRejection,
    getuserforweeklycall ,
    getChecklistMaster,
    addChecklistMaster,
    generateFormChecklist,
    updateChecklistForm,
    updateChecklistMaster,
    checklist_import,
    download_sample,
    deleteCommonTableData,
    get_clientusersnew,
    addClientReporting,
    getClientReporting
};

function addclient(client_data){

  const formData = new FormData();

    // Append client_data to FormData
    Object.keys(client_data).forEach(key => {
        formData.append(key, client_data[key]);
    });
    if(client_data.file){
    client_data.file.forEach((file, i) => {
      formData.append(`formFile[${i}]`,
      new Blob([file], { type: file.type }),
      file.name || ""
      );
    });
  }
    console.log(formData)
    return axios({
        method: "post",
        url: `${apiUrl}/companies`,
        data: formData,
        headers: { 
          Authorization: `Bearer ${authtoken}`,
          'Content-Type': 'multipart/form-data' // Set content type to multipart/form-data
      },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
}

function addjobclient(client_data){
  return axios({
      method: "post",
      url: `${apiUrl}/client-job-add`,
      data: client_data,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function updatejobclient(client_data){
  return axios({
      method: "post",
      url: `${apiUrl}/client-job-update`,
      data: client_data,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function createclient(client_data){
  // console.log('client data');
  // console.log(client_data); 
  var bodyFormData = new FormData();
  bodyFormData.append("name", client_data.name);
  bodyFormData.append("sub_client", client_data.sub_client);
  bodyFormData.append("account_prod_software",client_data.account_prod_software);
  bodyFormData.append("book_keeping_software", client_data.book_keeping_software);
  bodyFormData.append("client_deadline", client_data.client_deadline);
  bodyFormData.append("company_id", client_data.company_id);
  bodyFormData.append("due_date", client_data.client_deadline);
  bodyFormData.append("in_budget", client_data.in_budget);
  bodyFormData.append("budget", client_data.budget);
  bodyFormData.append("task_id", client_data.task_id);
  bodyFormData.append("year_end_date", client_data.year_end_date);
  bodyFormData.append("created_at", client_data.raised_on);
  bodyFormData.append("frequency", client_data.frequency);
  bodyFormData.append("type", client_data.type);
  bodyFormData.append("priority_no", client_data.priority_no);
  bodyFormData.append("vat_scheme", client_data.vat_scheme);
  bodyFormData.append("vat_qe", client_data.vat_qe);
  bodyFormData.append("vat_qe_date", client_data.vat_qe_date);
  bodyFormData.append("business_nature", client_data.business_nature);
  bodyFormData.append("freq_date", client_data.freq_date);
  bodyFormData.append("freq_day", client_data.freq_day);
  bodyFormData.append("task_type", client_data.task_type);
  bodyFormData.append("task_list", client_data.task_list);
  bodyFormData.append("other_report", client_data.other_report);
  bodyFormData.append("user_id", client_data.user_id);
  if(client_data.file){
  client_data.file.forEach((file, i) => {
    bodyFormData.append(`formFile[${i}]`,
    new Blob([file], { type: file.type }),
    file.name || ""
    );
  });
  }

  return axios({
    method: "post",
    url: `${apiUrl}/companies/update`,
    data: bodyFormData,
    headers: { 
      Authorization: `Bearer ${authtoken}`,
      'Content-Type': 'multipart/form-data' // Set content type to multipart/form-data
  },
  })
    .then(function (response) {
         return response;
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });  
}

function create_amendment(amendment_data){
  var current_date = new Date().toISOString().slice(0, 10);
  var bodyFormData = new FormData();
  bodyFormData.append("company_id", amendment_data.am_company);
  bodyFormData.append("client_id", amendment_data.am_analyst);
  bodyFormData.append("raised_date",current_date);
  bodyFormData.append("select_due_date", amendment_data.select_due_date);
  bodyFormData.append("type", 'A');
  bodyFormData.append("am_budget", amendment_data.am_budget);
  return axios({
    method: "post",
    url: `${apiUrl}/companies/amendmentcreate`,
    data: bodyFormData,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });  
}

function getphasestl(id){
  return axios({
      method: "get",
      url: `${apiUrl}/phase-wise-user?id=${id}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function getclientlist(){
  return axios({
    method: "get",
    url: `${apiUrl}/getuniquecompanies`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function getsubclientlist(client){
  let client1 = encodeURIComponent(client);
  return axios({
    method: "get",
    url: `${apiUrl}/getsubclient?client=${client1}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function getparticular_client(task_id){

  return axios({
    method: "get",
    url: `${apiUrl}/companies/get/${task_id}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
            return response;  
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}


function getjobdocs(task_id){
  return axios({
    method: "get",
    url: `${apiUrl}/uploaddocs/${task_id}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
            return response;  
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function getClientTransition(page,search,filter,filtertype,sortfield,sortorder){
  let filters = '';
  if(filter){
     filters = `&filters=${JSON.stringify(filter)}`;
  }
  let typefilter = '';
  if(filtertype!=''){
     typefilter = `&filtertype=${filtertype}`;
  }
  return axios({
    method: "get",
    url: `${apiUrl}/getTransitionList?page=${page}&search=${search}${filters}${typefilter}&sort=${sortfield}&order=${sortorder}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}
function gettransitionType(client_id){
  return axios({
    method: "get",
    url: `${apiUrl}/gettransitionType`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      console.log('aaa',response)
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function getTransitionFields(trans_id){
  return axios({
    method: "get",
    url: `${apiUrl}/getTransitionFields?trans_id=${trans_id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      console.log('aaa',response)
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function getTransitionFieldData(id){
  return axios({
    method: "get",
    url: `${apiUrl}/getTransitionFieldData?id=${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      console.log('aaa',response)
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function addtransitionFieldData(data){
  return axios({
    method: "post",
    url: `${apiUrl}/addtransitionFieldData`,
    data: data,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
  }
  function getuniqueclient(){
    return axios({
      method: "get",
      url: `${apiUrl}/getuniqueclient`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function getGovernanceCalltransdata(page,search,filter,filtertype,sortfield,sortorder){
    let filters = '';
    if (filter) {
      const encodedFilter = encodeURIComponent(JSON.stringify(filter));
      filters = `&filters=${encodedFilter}`;
  }
    let typefilter = '';
    if(filtertype!=''){
       typefilter = `&filtertype=${filtertype}`;
    }
    return axios({
      method: "get",
      url: `${apiUrl}/getgovernancetransdata?page=${page}&search=${search}${filters}${typefilter}&sort=${sortfield}&order=${sortorder}`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
        return response;
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });
  }
  
  function editgovernancedata(value,field,id,table=''){
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("field", field);
    bodyFormData.append("value", value);
    var api = 'governancedaatedit';
    if(table == 'weeklydaatedit' || table == 'internalcalledit'){
      api = table;
    }
    return axios({
        method: "POST",
        url: `${apiUrl}/${api}`,
        data: bodyFormData,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
          return response;
        }, error => {
         // return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
  }
  
  function getgovernance(id){
    return axios({
      method: "get",
      url: `${apiUrl}/getgovernance?id=${id}`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
        return response;
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });
  }
  
  function editgovernancetransdata(data){
    return axios({
      method: "post",
      url: `${apiUrl}/editgovernancetrans`,
      data: data,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function addgovernancetransdata(data){
    return axios({
      method: "post",
      url: `${apiUrl}/addgovernancetrans`,
      data: data,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function gettransitionData(client_id){
    return axios({
      method: "get",
      url: `${apiUrl}/getClientTransData?client_id=${client_id}`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
        console.log('aaa',response)
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function generateForm(id,client_id,trans_id){

    return axios({
      method: "get",
      url: `${apiUrl}/generateForm?id=${id}&client_id=${client_id}&trans_id=${trans_id}`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function generateSOPForm(client_id,subclient,trans_id){

    return axios({
      method: "get",
      url: `${apiUrl}/getsubclientSOP?subclient=${subclient}&client_id=${client_id}&trans_id=${trans_id}`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function updateTransitionForm(data){
    var bodyFormData = new FormData();
    bodyFormData.append("data", JSON.stringify(data));
    return axios({
      method: "post",
      url: `${apiUrl}/updateTransitionForm`,
      data: bodyFormData,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function updateTransitionSOPForm(data){
    var bodyFormData = new FormData();
    bodyFormData.append("data", JSON.stringify(data));
    return axios({
      method: "post",
      url: `${apiUrl}/updateTransitionSOPForm`,
      data: bodyFormData,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function updateTransData(client_id,trans_id,field,value){
    var bodyFormData = new FormData();
    bodyFormData.append("client_id", client_id);
    bodyFormData.append("trans_id", trans_id);
    bodyFormData.append("field", field);
    bodyFormData.append("value", value);
    return axios({
      method: "post",
      url: `${apiUrl}/updateTransData`,
      data: bodyFormData,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function getcllientfrequency(page){
    return axios({
      method: "get",
      url: `${apiUrl}/getclientfrequency?page=${page}`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
        return response;
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });
  }
  function addclientfrequency(client_data){
    return axios({
        method: "post",
        url: `${apiUrl}/addclientfrequency`,
        data: client_data,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
  }
  function getclientdetails(client_name,subclient_name){
    return axios({
      method: "get",
      url: `${apiUrl}/getclientdetails?client_name=${encodeURIComponent(client_name)}&subclient_name=${encodeURIComponent(subclient_name)}`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function getInternalGovernanceCalltransdata(page,search,filter,filtertype){
    let filters = '';
    if(filter){
       filters = `&filters=${JSON.stringify(filter)}`;
    }
    let typefilter = '';
    if(filtertype!=''){
       typefilter = `&filtertype=${filtertype}`;
    }
    return axios({
      method: "get",
      url: `${apiUrl}/getinternalgovernancetransdata?page=${page}&search=${search}${filters}${typefilter}`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
        return response;
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });
  }
  function getinternalgovernance(id){
    return axios({
      method: "get",
      url: `${apiUrl}/getinternalgovernance?id=${id}`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
        return response;
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });
  }
  function editinternalgovernancetransdata(data,flag){
    return axios({
      method: "post",
      url: `${apiUrl}/editinternalgovernancetrans?flag=${flag}`,
      data: data,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function addinternalgovernancetransdata(data,flag){
    return axios({
      method: "post",
      url: `${apiUrl}/addinternalgovernancetransdata?flag=${flag}`,
      data: data,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
    }
    function getusersclientlist(){
      return axios({
        method: "get",
        url: `${apiUrl}/getusersclientlist`,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
      .then(function (response) {
          return response;
        }, error => {
          return error.response;
        })
        .catch(function (error) {
          console.log(error.response.status);
        });
    }
    function getuniqueclientforsme(){
      return axios({
        method: "get",
        url: `${apiUrl}/getuniqueclientforsme`,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
           if(response.status == 200){
                return response;  
           }
        }, error => {
          return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    function getclienttransitionsmeData(client_id){
      return axios({
        method: "get",
        url: `${apiUrl}/getclienttransitionsmeData?client_id=${client_id}`,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
          console.log('aaa',response)
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    function getAppreciationList(page){
      return axios({
        method: "get",
        url: `${apiUrl}/getAppreciationList?page=${page}`,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
          console.log('aaa',response)
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    function getusers(){
      return axios({
        method: "get",
        url: `${apiUrl}/getusers`,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    function addclientappreciation(data){
      return axios({
        method: "post",
        url: `${apiUrl}/addclientappreciation`,
        data: data,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    function getsingleAppreciation(id){
      return axios({
        method: "get",
        url: `${apiUrl}/getsingleAppreciation?id=${id}`,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    function updateclientappreciation(data){
      return axios({
        method: "post",
        url: `${apiUrl}/updateclientappreciation`,
        data: data,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    function deleteClientAppreciation(id){
      return axios({
        method: "get",
        url: `${apiUrl}/deleteClientAppreciation?id=${id}`,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    function getclienttransitionDash(id){
      return axios({
        method: "get",
        url: `${apiUrl}/getclienttransitionDash?client=${id}`,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    
    function addsubclient(client_data){
      return axios({
          method: "post",
          url: `${apiUrl}/addsubclient`,
          data: client_data,
          headers: { Authorization: `Bearer ${authtoken}` },
        })
          .then(function (response) {
               return response;  
          }, error => {
            return error.response;
          //   console.log(error.response.status);
          })
          .catch(function (error) {
            // console.log(error.response.status);
          });      
  }
  
  function download_transitionform_report(client_id,trans_id){
    
    return axios({
      method: "get",
      responseType: 'blob', 
      url: `${apiUrl}/transition-form-export?client_id=${client_id}&trans_id=${trans_id}`,
      headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
    })
    .then(function (response) {
      const outputFilename = `transition-form-report-${Date.now()}.xlsx`;
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
  // return response;
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });  
  }
  function getInternalCalltransdata(page,search,filter,filtertype){
    let filters = '';
    if(filter){
       filters = `&filters=${JSON.stringify(filter)}`;
    }
    let typefilter = '';
    if(filtertype!=''){
       typefilter = `&filtertype=${filtertype}`;
    }
    return axios({
      method: "get",
      url: `${apiUrl}/getinternaltransdata?page=${page}&search=${search}${filters}${typefilter}`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
        return response;
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });
  }
  function addinternaltransdata(data,flag){
    return axios({
      method: "post",
      url: `${apiUrl}/addinternaltransdata?flag=${flag}`,
      data: data,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function getinternalcall(id){
    return axios({
      method: "get",
      url: `${apiUrl}/getinternalcall?id=${id}`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
        return response;
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });
  }
  function editinternaltransdata(data){
    return axios({
      method: "post",
      url: `${apiUrl}/editinternaltrans`,
      data: data,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }

  function updateQuestionaireForm(data,client,subclient){
    var bodyFormData = new FormData();
    bodyFormData.append("client", client);
    bodyFormData.append("sub_client", subclient);
    bodyFormData.append("data", JSON.stringify(data));
    return axios({
      method: "post",
      url: `${apiUrl}/updateQuestionaireForm`,
      data: bodyFormData,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function getquestionnaireData(client_id,subclient){
    return axios({
      method: "get",
      url: `${apiUrl}/getquestionnaireData?subclient=${encodeURIComponent(subclient)}&client_id=${client_id}`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function getquestionnaireDash(id){
    return axios({
      method: "get",
      url: `${apiUrl}/getquestionnaireDash?client=${id}`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function governanceCalldashboard(id,downloadexcel=0){
    if(downloadexcel==0){
    return axios({
      method: "get",
      url: `${apiUrl}/governanceCalldashboard?user_id=${id}`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
    }else{
      return axios({
        method: "get",
        responseType: 'blob', 
        url: `${apiUrl}/governanceCalldashboard?user_id=${id}&downloadexcel=${downloadexcel}`,
        headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
      })
      .then(function (response) {
        const outputFilename = `Governance-Dashboard-report-${Date.now()}.xlsx`;
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
    // return response;
        }, error => {
          return error.response;
        })
        .catch(function (error) {
          console.log(error.response.status);
        });  
    }
  }

  function getGovernanceMISdashboard(id,downloadexcel=0){
    if(downloadexcel==0){
      return axios({
        method: "get",
        url: `${apiUrl}/getGovernanceMISdashboard?user_id=${id}&downloadexcel=${downloadexcel}`,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
            return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
      }else{
        return axios({
          method: "get",
          responseType: 'blob', 
          url: `${apiUrl}/getGovernanceMISdashboard?user_id=${id}&downloadexcel=${downloadexcel}`,
          headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
        })
        .then(function (response) {
          const outputFilename = `MIS-governance-report-${Date.now()}.xlsx`;
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', outputFilename);
          document.body.appendChild(link);
          link.click();
      // return response;
          }, error => {
            return error.response;
          })
          .catch(function (error) {
            console.log(error.response.status);
          });  
      }
    }
    function getGovernanceMISActiondashboard(id,downloadexcel=0){
      if(downloadexcel==0){
      return axios({
        method: "get",
        url: `${apiUrl}/getGovernanceMISActiondashboard?user_id=${id}&downloadexcel=${downloadexcel}`,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
      }else{
        return axios({
          method: "get",
          responseType: 'blob', 
          url: `${apiUrl}/getGovernanceMISActiondashboard?user_id=${id}&downloadexcel=${downloadexcel}`,
          headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
        })
        .then(function (response) {
          const outputFilename = `MIS-governance-action-report-${Date.now()}.xlsx`;
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', outputFilename);
          document.body.appendChild(link);
          link.click();
      // return response;
          }, error => {
            return error.response;
          })
          .catch(function (error) {
            console.log(error.response.status);
          });  
      }
      }
      function editgovernancefreqdata(value,field,id,table=''){
        var bodyFormData = new FormData();
        bodyFormData.append("id", id);
        bodyFormData.append("field", field);
        bodyFormData.append("value", value);
        var api = 'editgovernancefreqdata';
    
        return axios({
            method: "POST",
            url: `${apiUrl}/${api}`,
            data: bodyFormData,
            headers: {Authorization: `Bearer ${authtoken}` },
          })
            .then(function (response) {
              return response;
            }, error => {
             // return error.response;
              console.log(error.response.status);
            })
            .catch(function (error) {
              // console.log(error.response.status);
            });      
      }
      function getclientfreq(user_id){
        return axios({
          method: "get",
          url: `${apiUrl}/getclientfreq?user_id=${user_id}`,
          headers: { Authorization: `Bearer ${authtoken}` },
        })
        .then(function (response) {
            return response;
          }, error => {
            return error.response;
          })
          .catch(function (error) {
            console.log(error.response.status);
          });
      }

      function governanceCalldashboarddetails(month,title,username){
        return axios({
          method: "get",
          url: `${apiUrl}/getGovernanceMISdashboarddetails?month_year=${month}&type=${title}&user_id=${username}`,
          headers: { Authorization: `Bearer ${authtoken}` },
        })
          .then(function (response) {
               return response;  
          }, error => {
            return error.response;
          //   console.log(error.response.status);
          })
          .catch(function (error) {
            // console.log(error.response.status);
          });
      }
      function governanceCalldashboardactiondetails(month,title,username){
        return axios({
          method: "get",
          url: `${apiUrl}/getGovernanceMISActiondetails?month_year=${month}&type=${title}&user_id=${username}`,
          headers: { Authorization: `Bearer ${authtoken}` },
        })
          .then(function (response) {
               return response;  
          }, error => {
            return error.response;
          //   console.log(error.response.status);
          })
          .catch(function (error) {
            // console.log(error.response.status);
          });
      }
function getclient(){
  return axios({
    method: "get",
    url: `${apiUrl}/getclient`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function getsubclient(client){
  let client1 = encodeURIComponent(client);
  return axios({
    method: "get",
    url: `${apiUrl}/getsubclientlist?client=${client1}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function mergeClient(userdata){
  var bodyFormData = new FormData();
  bodyFormData.append("client", userdata.client);
  bodyFormData.append("mergeto",userdata.mergeto);
  bodyFormData.append("mergewith",userdata.mergewith);
  
  return axios({
    method: "post",
    url: `${apiUrl}/mergeClient`,
    data:bodyFormData,
    headers: { "Content-Type": "application/json",Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
            return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
       return error;
      // console.log(error.response.status);
    });  
}

function saveManagerRejection(data){
  return axios({
    method: "post",
    url: `${apiUrl}/saveManagerRejection`,
    data: data,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function getfilterlist(){
  return axios({
    method: "get",
    url: `${apiUrl}/client-filter-list`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}
function editManagerRejection(data){
  return axios({
    method: "post",
    url: `${apiUrl}/editManagerRejection`,
    data: data,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function getuserforweeklycall(){
  return axios({
    method: "get",
    url: `${apiUrl}/getuserforweeklycall`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function getChecklistMaster(){
  return axios({
    method: "get",
    url: `${apiUrl}/getChecklistMaster`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function generateFormChecklist(id,phase_id,sub_cat){

  return axios({
    method: "get",
    url: `${apiUrl}/generateFormChecklist?id=${id}&phase_id=${phase_id}&sub_cat=${sub_cat}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function updateChecklistForm(data){
  var bodyFormData = new FormData();
  bodyFormData.append("data", JSON.stringify(data));
  return axios({
    method: "post",
    url: `${apiUrl}/updateChecklistForm`,
    data: bodyFormData,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function updateChecklistMaster(id,field,value){
  var bodyFormData = new FormData();
  bodyFormData.append("id", id);
  bodyFormData.append("field", field);
  bodyFormData.append("value", value);
  return axios({
    method: "post",
    url: `${apiUrl}/updateChecklistMaster`,
    data: bodyFormData,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function addChecklistMaster(data){
  var bodyFormData = new FormData();
  bodyFormData.append("data", JSON.stringify(data));
  return axios({
    method: "post",
    url: `${apiUrl}/addChecklistMaster`,
    data: bodyFormData,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function checklist_import(master_id,phase_id,file,csubcat){
  var bodyFormData = new FormData();
  bodyFormData.append("master_id", master_id);
  bodyFormData.append("phase_id",phase_id);
  bodyFormData.append("frequency",csubcat);
  bodyFormData.append("file",file);
  return axios({
    method: "post",
    url: `${apiUrl}/checklist_import`,
    data:bodyFormData,
    headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
  }).then(function (response) {
    if(response.status == 200){
       return response;  
    }
 }, error => {
     return error.response;
   // console.log(error.response.status);
 })
 .catch(function (error) {
   // console.log(error.response.status);
 });  
}
function download_sample(){
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/download_sample`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `sample.xlsx`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  

}

function deleteCommonTableData(id,table){
  var bodyFormData = new FormData();
  bodyFormData.append("id", id);
  bodyFormData.append("table",table);
  return axios({
    method: "post",
    data:bodyFormData,
    url: `${apiUrl}/deleteCommonTableData`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function get_clientusersnew(){
  return axios({
    method: "get",
    url: `${apiUrl}/getclientuserlistnew`,
    headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      console.log(response);
      // localStorage.setItem("task_count", JSON.stringify(response.data.data));
      return response;
    })
    .catch(function (error) {
      return error;
    }); 
}
function addClientReporting(inputFields){
  console.log(inputFields)
  var formData = new FormData();
  inputFields.formData.forEach((fileGroup, index) => {
    // Append metadata
    formData.append(`client_id[${index}]`, fileGroup.client_id);
    formData.append(`type[${index}]`, fileGroup.type);
    formData.append(`month[${index}]`, fileGroup.month);

    fileGroup.uploadfiles.forEach((file1, fileIndex) => {
        if (file1.file) {
            // Append file only if it exists
            formData.append(
                `file[${index}][${fileIndex}]`,
                file1.file,
                file1.file.name || `file_${fileIndex}`
            );
        }

        // Append additional metadata
        formData.append(`valid[${index}][${fileIndex}]`, file1.valid);
        formData.append(`errors[${index}][${fileIndex}]`, JSON.stringify(file1.errors));
    });
});
  return axios({
    method: "post",
    url: `${apiUrl}/addClientReporting`,
    data:formData,
    headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
  }).then(function (response) {
    if(response.status == 200){
       return response;  
    }
 }, error => {
     return error.response;
   // console.log(error.response.status);
 })
 .catch(function (error) {
   // console.log(error.response.status);
 });  
}
function getClientReporting(page,per_page,start_date,end_date){
  return axios({
    method: "get",
    url: `${apiUrl}/getClientReporting?page=${page}&per_page=${per_page}&start_date=${start_date}&end_date=${end_date}`,
    headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      console.log(response);
      // localStorage.setItem("task_count", JSON.stringify(response.data.data));
      return response;
    })
    .catch(function (error) {
      return error;
    }); 
}
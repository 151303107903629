import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Onetimeresetpass from "views/examples/Onetimeresetpass";
import Forgotpassword from "views/examples/Forgotpassword";
import Resetpassword from "views/examples/Resetpassword";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";
import Dashboard from "views/user/Dashboard.js";
import User from "views/user/users/User.js";
import Role from "views/user/roles/Role.js";

import AnalystDashboard from "views/analyst/Dashboard.js";
import Addclient from "views/analyst/client/AddClient.js";
import Archive from "views/analyst/Archive.js";

// phases
import Phaselist from "views/user/phase/Phaselist.js";
import AnalyticsReport from "views/analyst/analytics/AnalyticsReport.js";
import ClientDashboard from "views/client/Dashboard.js";
import Categorylist from "views/user/category/Categorylist";
import RejectionReport from "views/analyst/analytics/RejectionReport";
import QueryResponses from "views/analyst/QueryResponses";
import ExternalRejection from "views/client/ExternalRejection";
import GovernanceCall from "views/client/GovernanceCall";
import ClientTransition from "views/client/ClientTransition";
import InternalGovernanceCall from "views/client/InternalGovernanceCall";
import ClientTransitionSme from "views/client/ClientTransitionSme";
import ClientAppreciationList from "views/client/ClientAppreciationList";
import InternalCall from "views/client/InternalCall";
import Announce from "views/client/Announce";

import AddMasterKRO from "views/user/users/AddMasterKRO";
import Krolisting from "views/user/users/KROListing";
import GovernanceDashboard from "views/client/GovernanceDashboard";
import KRODashboard from "views/user/users/KRODashboard";
import AssignClient from "views/client/AssignClient";
import StopRecurring from "views/user/users/StopRecurring";
import ManagerRejection from "views/client/ManagerRejection";
import Merging from "views/client/Merging.js";
import KROTypeRating from "views/user/users/KROTypeRating";
import ClientUser from "views/client/User.js";
import ClientReporting from "views/client/ClientReporting.js";

import AddMasterChecklist from "views/client/AddMasterChecklist";
// var user_info = {rolename:'default'};
// if(user_info != null){
//     if(JSON.parse(localStorage.getItem('user-info')) != null){
//         // user_info = JSON.parse(localStorage.getItem('user-info'));  
//         user_info = JSON.parse(localStorage.getItem('user-info')).user[0];
//     }else{
//         user_info.rolename = 'default';
//     }
// }

var user_info = {rolename:'default'};
if(user_info != null){
    if(JSON.parse(localStorage.getItem('user-info')) != null){
        user_info = JSON.parse(localStorage.getItem('user-info'));  
        if(user_info.roletype == 'SME' && user_info.rolename == 'Client'){
          user_info.rolename = user_info.rolename+'_sme';
        }
    }else{
        user_info.rolename = 'default';
    }
}


  switch (user_info.rolename) {
    case 'admin':
      var routes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-ui-04 text-th",
          component: Dashboard,
          layout: "/admin",
        },

        {
          path: "/users",
          name: "Users",
          icon: "ni ni-single-02 text-th",
          component: User,
          layout: "/admin",
        },
        {
          path: "/phase",
          name: "Phase",
          icon: "fa fa-sort-alpha-desc",
          component: Phaselist,
          layout: "/admin",
        },
        {
          path: "/roles",
          name: "Roles",
          icon: "fa fa-lock",
          component: Role,
          layout: "/admin",
        },
        {
          path: "/categories",
          name: "Categories",
          icon: "fa fa-lock",
          component: Categorylist,
          layout: "/admin",
        },
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-settings-gear-65",
          component: Profile,
          layout: "/admin",
        },
        
      ];
      break;
    case 'qanalyst':
      var routes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-ui-04 text-th",
          component: AnalystDashboard,
          layout: "/admin",
        },
        {
          path: "/announce",
          name: "Announcement",
          icon: "ni ni-single-copy-04 text-th",
          component: Announce,
          layout: "/admin",
        },
        // {
        //   path: "/client",
        //   name: "Clients",
        //   icon: "ni ni-tv-2 text-th",
        //   component: ClientDashboard,
        //   layout: "/admin",
        // },
        // {
        //   path: "/archive",
        //   name: "Archived",
        //   icon: "ni ni-archive-2 text-th",
        //   component: Archive,
        //   layout: "/admin",
        // },
        // {
        //   path: "/users",
        //   name: "Users",
        //   icon: "ni ni-single-02 text-th",
        //   component: User,
        //   layout: "/admin",
        // },
        // {
        //   path: "/user-profile",
        //   name: "User Profile",
        //   icon: "ni ni-settings-gear-65",
        //   component: Profile,
        //   layout: "/admin",
        // },
        {
          path: "/analytics_report",
          name: "Report",
          icon: "ni ni-single-copy-04 text-th",
          component: AnalyticsReport,
          layout: "/admin",
        },
        // (user_info.department === 'BookKeeping')?
        // {
        //   path: "/clienttransition",
        //   name: "Client Transition",
        //   icon: "ni ni-single-copy-04 text-th",
        //   component: ClientTransition,
        //   layout: "/admin",
        // }: { },
        (user_info.role_id != 16 && user_info.role_id != 15)?
       {  path: "/responsequery",
          name: "Query Responses",
          icon: "ni ni-single-copy-04 text-th",
          component: QueryResponses,
          layout: "/admin",
        }: '',
        // (user_info.role_id != 16 && user_info.role_id != 15)?
        // {
        //   path: "/rejectionreport",
        //   name: "Internal CCRP",
        //   icon: "ni ni-single-copy-04 text-th",
        //   component: RejectionReport,
        //   layout: "/admin",
        // } : '',
        // {
        //   path: "/externalrejection",
        //   name: "External CCRP",
        //   icon: "ni ni-single-copy-04 text-th",
        //   component: ExternalRejection,
        //   layout: "/admin",
        // },
        // {
        //   path: "/governance",
        //   name: "Governance",
        //   icon: "ni ni-single-copy-04 text-th",
        //   component: GovernanceCall,
        //   layout: "/admin",
        // },
        // {
        //   path: "/internal-governance",
        //   name: "Weekly Call",
        //   icon: "ni ni-single-copy-04 text-th",
        //   component: InternalGovernanceCall,
        //   layout: "/admin",
        // },
        // {
        //   path: "/client-appreciation",
        //   name: "Client Appreciation",
        //   icon: "ni ni-single-copy-04 text-th",
        //   component: ClientAppreciationList,
        //   layout: "/admin",
        // },
        // {
        //   path: "/internal-call",
        //   name: "Internal Call",
        //   icon: "ni ni-single-copy-04 text-th",
        //   component: InternalCall,
        //   layout: "/admin",
        // }, 
        {
          path:'1',
           name: "User",
           icon: "ni ni-single-02 text-th",
           layout: "/admin",
           submenu:[
            {
              path: "/users",
              name: "Users",
              icon: "ni ni-single-02 text-th",
              component: User,
              layout: "/admin",
            },
            {
              path: "/user-profile",
              name: "User Profile",
              icon: "ni ni-settings-gear-65",
              component: Profile,
              layout: "/admin",
            },
            {
              path: "/archive",
              name: "Archived",
              icon: "ni ni-archive-2 text-th",
              component: Archive,
              layout: "/admin",
            },
            {
              path: "/stoprecurring",
              name: "Stop User Recurring",
              icon: "ni ni-archive-2 text-th",
              component: StopRecurring,
              layout: "/admin",
            }
          ]
        },
        {
          path:'2',
           name: "Client",
           icon: "ni ni-tv-2 text-th",
           layout: "/admin",
           submenu:[
            {
              path: "/client",
              name: "Clients",
              icon: "ni ni-tv-2 text-th",
              component: ClientDashboard,
              layout: "/admin",
            },
            (user_info.department === 'BookKeeping')?
            {
              path: "/clienttransition",
              name: "Client Transition",
              icon: "ni ni-single-copy-04 text-th",
              component: ClientTransition,
              layout: "/admin",
            }: { },
            {
              path: "/client-appreciation",
              name: "Client Appreciation",
              icon: "ni ni-single-copy-04 text-th",
              component: ClientAppreciationList,
              layout: "/admin",
            },{
              path: "/assignclient",
              name: "Assign Client",
              icon: "ni ni-single-copy-04 text-th",
              component: AssignClient,
              layout: "/admin",
            },{
              path: "/merging",
              name: "Merging Client",
              icon: "ni ni-single-copy-04 text-th",
              component: Merging,
              layout: "/admin",
            },
            (user_info.department == 'SME')?
            {
              path: "/clientReporting",
              name: "Client Reporting",
              icon: "ni ni-single-copy-04 text-th",
              component: ClientReporting,
              layout: "/admin",
            }: { }
          ]
         },
        {
          path:'3',
           name: "Governance",
           icon: "ni ni-single-02 text-th",
           layout: "/admin",
           submenu:[
            {
              path: "/governancedashboard",
              name: "Dashboard",
              icon: "ni ni-single-copy-04 text-th",
              component: GovernanceDashboard,
              layout: "/admin",
            },
            {
             path: "/governance",
             name: "External Governance",
             icon: "ni ni-single-copy-04 text-th",
             component: GovernanceCall,
             layout: "/admin",
           },
           {
             path: "/internal-governance",
             name: "Weekly Call",
             icon: "ni ni-single-copy-04 text-th",
             component: InternalGovernanceCall,
             layout: "/admin",
           }, {
             path: "/internal-call",
             name: "Internal Call",
             icon: "ni ni-single-copy-04 text-th",
             component: InternalCall,
             layout: "/admin",
           }]
         },
         {
           path:'4',
            name: "CCRP",
            icon: "ni ni-single-02 text-th",
            layout: "/admin",
            submenu:[
              (user_info.role_id != 16 && user_info.role_id != 15)?
              {
                path: "/rejectionreport",
                name: "Internal CCRP",
                icon: "ni ni-single-copy-04 text-th",
                component: RejectionReport,
                layout: "/admin",
              } : '',
              {
             path: "/externalrejection",
             name: "External CCRP",
             icon: "ni ni-single-copy-04 text-th",
             component: ExternalRejection,
             layout: "/admin",
           },
           {
            path: "/managerrejection",
            name: "Manager Rejection",
            icon: "ni ni-single-copy-04 text-th",
            component: ManagerRejection,
            layout: "/admin",
          }
          ]
          },
          {
            path:'4',
             name: "KRO",
             icon: "ni ni-single-02 text-th",
             layout: "/admin",
             submenu:[
              {
                path: "/krodashboard",
                name: "KRO Dashboard",
                icon: "ni ni-single-02 text-th",
                component: KRODashboard,
                layout: "/admin",
              },
              {
              path: "/Krolisting",
              name: "KRO's",
              icon: "ni ni-single-02 text-th",
              component: Krolisting,
              layout: "/admin",
            },{
              path: "/addmasterkro",
              name: "KRO Master",
              icon: "ni ni-single-02 text-th",
              component: AddMasterKRO,
              layout: "/admin",
            },{
              path: "/krotyperating",
              name: "KRO Type Rating",
              icon: "ni ni-single-02 text-th",
              component: KROTypeRating,
              layout: "/admin",
            }]
          },
         
         
        
      ];
      break;
      case 'teamleader':
      var routes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: AnalystDashboard,
          layout: "/admin",
        },
        (user_info.role_id != 15)?
        {
          path: "/users",
          name: "Users",
          icon: "ni ni-single-02 text-th",
          component: User,
          layout: "/admin",
        } : { },
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-single-02 text-th",
          component: Profile,
          layout: "/admin",
        },
        {
          path: "/analytics_report",
          name: "Report",
          icon: "ni ni-single-copy-04 text-th",
          component: AnalyticsReport,
          layout: "/admin",
        },
        {
          path: "/responsequery",
          name: "Query Responses",
          icon: "ni ni-single-copy-04 text-th",
          component: QueryResponses,
          layout: "/admin",
        },
        {
          path: "/rejectionreport",
          name: "Internal CCRP",
          icon: "ni ni-single-copy-04 text-th",
          component: RejectionReport,
          layout: "/admin",
        },
        {
          path: "/Krolisting",
          name: "KRO's",
          icon: "ni ni-single-02 text-th",
          component: Krolisting,
          layout: "/admin",
        },{
          path: "/addmasterkro",
          name: "KRO Master",
          icon: "ni ni-single-02 text-th",
          component: AddMasterKRO,
          layout: "/admin",
        }
      ];
      break;
    case 'team':
      var routes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: AnalystDashboard,
          layout: "/admin",
        },
        
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-single-02 text-th",
          component: Profile,
          layout: "/admin",
        },
        {
          path: "/analytics_report",
          name: "Report",
          icon: "ni ni-single-copy-04 text-th",
          component: AnalyticsReport,
          layout: "/admin",
        },
        {
          path: "/responsequery",
          name: "Query Responses",
          icon: "ni ni-single-copy-04 text-th",
          component: QueryResponses,
          layout: "/admin",
        },
        {
          path: "/rejectionreport",
          name: "Internal CCRP",
          icon: "ni ni-single-copy-04 text-th",
          component: RejectionReport,
          layout: "/admin",
        },
        {
          path: "/Krolisting",
          name: "KRO's",
          icon: "ni ni-single-copy-04 text-th",
          component: Krolisting,
          layout: "/admin",
        }
      ];
      break;
      case 'manager':
        var routes=[
          {
            path: "/index",
            name: "Dashboard",
            icon: "ni ni-ui-04 text-th",
            component: AnalystDashboard,
            layout: "/admin",
          },
          {
            path: "/analytics_report",
            name: "Report",
            icon: "ni ni-single-copy-04 text-th",
            component: AnalyticsReport,
            layout: "/admin",
          },
          {
            path: "/users",
            name: "Users",
            icon: "ni ni-single-02 text-th",
            component: User,
            layout: "/admin",
          },
          {
            path: "/user-profile",
            name: "User Profile",
            icon: "ni ni-settings-gear-65",
            component: Profile,
            layout: "/admin",
          },
          {
            path: "/announce",
            name: "Announcement",
            icon: "ni ni-single-copy-04 text-th",
            component: Announce,
            layout: "/admin",
          },
          {
            path:'1',
             name: "Governance",
             icon: "ni ni-single-02 text-th",
             layout: "/admin",
             submenu:[
              {
                path: "/governancedashboard",
                name: "Dashboard",
                icon: "ni ni-single-copy-04 text-th",
                component: GovernanceDashboard,
                layout: "/admin",
              },
              {
               path: "/governance",
               name: "External Governance",
               icon: "ni ni-single-copy-04 text-th",
               component: GovernanceCall,
               layout: "/admin",
             },
             {
               path: "/internal-governance",
               name: "Weekly Call",
               icon: "ni ni-single-copy-04 text-th",
               component: InternalGovernanceCall,
               layout: "/admin",
             }, {
               path: "/internal-call",
               name: "Internal Call",
               icon: "ni ni-single-copy-04 text-th",
               component: InternalCall,
               layout: "/admin",
             }]
           },
           {
            path:'2',
             name: "CCRP",
             icon: "ni ni-single-02 text-th",
             layout: "/admin",
             submenu:[
               
               {
                 path: "/rejectionreport",
                 name: "Internal CCRP",
                 icon: "ni ni-single-copy-04 text-th",
                 component: RejectionReport,
                 layout: "/admin",
               },
               {
              path: "/externalrejection",
              name: "External CCRP",
              icon: "ni ni-single-copy-04 text-th",
              component: ExternalRejection,
              layout: "/admin",
            },
            {
              path: "/managerrejection",
              name: "Manager Rejection",
              icon: "ni ni-single-copy-04 text-th",
              component: ManagerRejection,
              layout: "/admin",
            }
          ]
           },
           {
            path:'3',
             name: "Client",
             icon: "ni ni-tv-2 text-th",
             layout: "/admin",
             submenu:[
              {
                path: "/client",
                name: "Clients",
                icon: "ni ni-tv-2 text-th",
                component: ClientDashboard,
                layout: "/admin",
              },
              {
                path: "/client-appreciation",
                name: "Client Appreciation",
                icon: "ni ni-single-copy-04 text-th",
                component: ClientAppreciationList,
                layout: "/admin",
              },
              (user_info.department === 'BookKeeping')?
          {
            path: "/clienttransition",
            name: "Client Transition",
            icon: "ni ni-single-copy-04 text-th",
            component: ClientTransition,
            layout: "/admin",
          }: { },
          (user_info.department === 'SME')?
          {
            path: "/clienttransitionsme",
            name: "SME Client Transition",
            icon: "ni ni-single-copy-04 text-th",
            component: ClientTransitionSme,
            layout: "/admin",
          }: { },{
            path: "/assignclient",
            name: "Assign Client",
            icon: "ni ni-single-copy-04 text-th",
            component: AssignClient,
            layout: "/admin",
          },{
            path: "/merging",
            name: "Merging Client",
            icon: "ni ni-single-copy-04 text-th",
            component: Merging,
            layout: "/admin",
          },  {
            path: "/clientReporting",
            name: "Client Reporting",
            icon: "ni ni-single-copy-04 text-th",
            component: ClientReporting,
            layout: "/admin",
          }
        
        ]
           },
           {
            path:'4',
             name: "KRO",
             icon: "ni ni-single-02 text-th",
             layout: "/admin",
             submenu:[
              {
                path: "/krodashboard",
                name: "KRO Dashboard",
                icon: "ni ni-single-02 text-th",
                component: KRODashboard,
                layout: "/admin",
              },
              {
              path: "/Krolisting",
              name: "KRO's",
              icon: "ni ni-single-02 text-th",
              component: Krolisting,
              layout: "/admin",
            },{
              path: "/addmasterkro",
              name: "KRO Master",
              icon: "ni ni-single-02 text-th",
              component: AddMasterKRO,
              layout: "/admin",
            },{
              path: "/krotyperating",
              name: "KRO Type Rating",
              icon: "ni ni-single-02 text-th",
              component: KROTypeRating,
              layout: "/admin",
            }]
          },
          {
            path:'5',
             name: "Checklist",
             icon: "ni ni-tv-2 text-th",
             layout: "/admin",
             submenu:[{
              path: "/checklistmaster",
              name: "Checklist Master",
              icon: "ni ni-tv-2 text-th",
              component: AddMasterChecklist,
              layout: "/admin",
            },
            // {
            //   path: "/checklistfeedback",
            //   name: "Checklist Feedback",
            //   icon: "ni ni-tv-2 text-th",
            //   component: FeedbackChecklist,
            //   layout: "/admin",
            // }
          ]
          },
        ];
        break;
        case 'Client_sme':
          var routes=[
            {
              path: "/index",
              name: "Dashboard",
              icon: "ni ni-single-copy-04 text-th",
              component: ClientDashboard,
              layout: "/admin",
            },
            
            {
              path: "/user-profile",
              name: "User Profile",
              icon: "ni ni-settings-gear-65",
              component: Profile,
              layout: "/admin",
            }
          ];
          break;
        case 'Client':
          var routes=[
            {
              path: "/index",
              name: "Dashboard",
              icon: "ni ni-single-copy-04 text-th",
              component: ClientDashboard,
              layout: "/admin",
            },
            {
              path: "/clientuser",
              name: "Users",
              icon: "ni ni-single-02 text-th",
              component: ClientUser,
              layout: "/admin",
            } ,
            
            {
              path: "/user-profile",
              name: "User Profile",
              icon: "ni ni-settings-gear-65",
              component: Profile,
              layout: "/admin",
            }
          ];
          break;
        case 'HR':
        var routes=[
          
          {
            path: "/krodashboard",
            name: "KRO Dashboard",
            icon: "ni ni-single-02 text-th",
            component: KRODashboard,
            layout: "/admin",
          },
          {
            path: "/addmasterkro",
            name: "KRO Master",
            icon: "ni ni-single-02 text-th",
            component: AddMasterKRO,
            layout: "/admin",
          },
          {
            path: "/Krolisting",
            name: "KRO's",
            icon: "ni ni-single-02 text-th",
            component: Krolisting,
            layout: "/admin",
          },
          
          
        ];
        break;
    default:
      var routes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: Dashboard,
          layout: "/admin",
        },
        
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-single-02 text-th",
          component: Profile,
          layout: "/admin",
        },
        
        {
          path: "/login",
          name: "Login",
          icon: "ni ni-key-25 text-th",
          component: Login,
          layout: "/auth",
        },
        {
          path: "/register",
          name: "Register",
          icon: "ni ni-circle-08 text-th",
          component: Register,
          layout: "/auth",
        },
        {
          path: "/resetpassword",
          name: "Resetpassword",
          icon: "ni ni-circle-08 text-th",
          component: Resetpassword,
          layout: "/auth",
        },
        {
          path: "/forgotpassword",
          name: "Forgotpassword",
          icon: "ni ni-circle-08 text-th",
          component: Forgotpassword,
          layout: "/auth",
        },
        {
          path: "/userreset",
          name: "UserReset",
          icon: "ni ni-circle-08 text-th",
          component: Onetimeresetpass,
          layout: "/auth",
        },
      ];
  
  }

export default routes;
